<template>
  <div>
    <modal
      v-if="show_modal"
      :label="`${editing ? 'Edit Experience' : 'Add Experience'}`"
      :submit_btn_text="'Save'"
      @submit="saveExperience($event)"
      @close="closeModal"
      blocking
      medium
      locked
    >
      <template v-slot:content>
        <AddEditExperience v-model="item_to_edit" />
      </template>
    </modal>

    <div class="flex">
      <div class="flex-1">
        <label class="mb-2" for="student-textbox-filter"> Search </label>
        <input
          id="experience"
          type="text"
          v-model="filterText"
          class="border-steel-gray rounded h-8 w-5/6 ml-2"
        />
      </div>
      <div class="flex-1">
        <label class="mb-2" for="student-textbox-filter"> Category </label>
        <select
          id="dropdown-experience-categories"
          class="text-sm p-1 ml-2 border-steel-gray rounded h-8 w-5/6"
          v-model="selected_cat"
          @change="setCat($event)"
        >
          <option value="">Choose Cateogry</option>
          <option
            v-for="cat in categories"
            :key="cat.experience_category_id"
            :value="cat.experience_category_id"
          >
            {{ cat.description }}
          </option>
        </select>
      </div>
      <div class="flex-none text-right">
        <Button class="" text="Add New" @click="show_modal = true" />
      </div>
    </div>
    <div v-if="isLoading" class="profile_box">
      <LoadingSpinner />
    </div>
    <div v-else class="profile_box">
      <div>
        <dynamic-table
          :records="this.filtered_exps"
          :fields="tableConfig"
          no-data-msg="Select a Category"
          striped
          hover
        >
          <template #cell(display)="{ item }">
            <div
              class="ml-5 w-5 rounded-full text-center"
              :class="{
                'bg-alert-success': item.display,
              }"
            >
              <font-awesome-icon v-if="item.display" icon="check" />
              <font-awesome-icon v-else icon="ban" />
            </div>
          </template>
          <template #cell(edit)="{ item }">
            <Button text="Edit" size="small" @click="openToEdit(item)" />
          </template>
          <template #cell(hide)="{ item }">
            <Button
              v-if="item.display"
              text="Hide"
              size="small"
              @click="hideExperience(item)"
            />
            <Button
              v-else
              text="Show"
              size="small"
              @click="showExperience(item)"
            />
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Button from "@/components/reusable-components/base-level-components/Button";
import AddEditExperience from "@/views/admin/sys-administration/experiences/AddEditExperience";
import { del, get, post, put } from "@/composables/httpUtil";
import { emitAlert } from "@/composables/alerts";

export default {
  name: "ExpExperiences",
  components: {
    LoadingSpinner,
    DynamicTable,
    Button,
    AddEditExperience,
    Modal,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selected_cat: null,
      experiences: [],
      filtered_exps: [],
      filterText: "",
      show_modal: false,
      editing: false,
      item_to_edit: {
        experience_id: "",
        description: "",
      },
      tableConfig: [
        {
          display: "Experience Name",
          name: "description",
          sortable: false,
          class: "",
        },
        {
          display: "Displayed",
          name: "display",
          sortable: false,
          class: "",
        },
        {
          display: "Edit",
          name: "edit",
          sortable: false,
          class: "",
        },
        {
          display: "Hide / Show",
          name: "hide",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.getExperience();
  },
  methods: {
    setCat(event) {
      this.selected_cat = event.target.value;
      this.getExperience();
    },
    getExperience() {
      if (this.selected_cat) {
        this.isLoading = true;
        let url = `admin/categories/${this.selected_cat}/experiences`;
        url += this.filterText !== "" ? `?name=${this.filterText}` : "";
        get(url).then((data) => {
          this.experiences = data;
          this.experiences.sort((a, b) => {
            return a.description.localeCompare(b.description);
          });
          this.filtered_exps = data;
          this.isLoading = false;
        });
      }
    },
    openToEdit(item) {
      this.item_to_edit = item;
      this.editing = true;
      this.show_modal = true;
    },
    saveExperience() {
      if (this.item_to_edit.description) {
        if (this.item_to_edit.experience_id) {
          put(
            `admin/categories/${this.selected_cat}/experiences/${this.item_to_edit.experience_id}`,
            this.item_to_edit
          ).then((data) => {
            emitAlert("success", `${data.description} Updated`, {
              timeout: 5000,
            });
          });
        } else {
          delete this.item_to_edit.experience_id;
          post(
            `admin/categories/${this.selected_cat}/experiences`,
            this.item_to_edit
          ).then((data) => {
            this.experiences.push(data);
            this.experiences.sort((a, b) => {
              return a.description.localeCompare(b.description);
            });
            this.filtered_exps = this.experiences;
            emitAlert("success", `${data.description} Added`, {
              timeout: 5000,
            });
          });
        }
        this.editing = false;
        this.show_modal = false;
        this.item_to_edit = {
          experience_id: "",
          description: "",
        };
      } else {
        emitAlert("error", `Experience Needed`, {
          timeout: 5000,
        });
      }
    },
    hideExperience(item) {
      item.display = false;
      del(
        `admin/categories/${this.selected_cat}/experiences/${item.experience_id}`
      ).then((data) => {
        emitAlert("success", `${data.description} Hidden`, {
          timeout: 5000,
        });
      });
    },
    showExperience(item) {
      item.display = true;
      put(`admin/categories/${this.selected_cat}/experiences/${item.experience_id}`, item).then((data) => {
        emitAlert("success", `${data.description} Shown`, {
          timeout: 5000,
        });
      });
    },
    closeModal() {
      this.show_modal = false;
      this.editing = false;
      this.item_to_edit = {
        experience_id: "",
        description: "",
      };
    },
  },
  watch: {
    filterText: {
      handler() {
        if (this.filterText) {
          this.filtered_exps = this.experiences.filter((c) => {
            return c.description
              .toLowerCase()
              .includes(this.filterText.toLowerCase());
          });
        } else {
          this.filtered_exps = this.experiences;
        }
      },
    },
  },
};
</script>

<style scoped></style>
