<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-experience">
      Experiences
    </div>
    <div class="box-style">
      <div class="flex flex-wrap">
        <div class="w-full">
          <ul
            class="flex mb-0 list-none flex-wrap flex-row border-b border-cool-gray"
          >
            <li class="-mb-px mr-2 last:mr-0 text-center">
              <a
                class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal"
                v-on:click="toggleTabs(1)"
                v-bind:class="{
                  'bg-whisper': openTab !== 1,
                  '': openTab === 1,
                }"
              >
                Experiences
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0 text-center">
              <a
                class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal"
                v-on:click="toggleTabs(2)"
                v-bind:class="{
                  'bg-whisper': openTab !== 2,
                  '': openTab === 2,
                }"
              >
                Categories
              </a>
            </li>
          </ul>
          <div
            class="flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded"
          >
            <div class="px-3 py-5 flex-auto">
              <div
                v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
              >
                <ExpExperiences
                  :categories="categories"
                ></ExpExperiences>
              </div>
              <div
                v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
              >
                <ExpCategories
                  @reloadCats="getCategory"
                  v-model="categories"
                ></ExpCategories>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpExperiences from "@/views/admin/sys-administration/experiences/ExpExperiences";
import ExpCategories from "@/views/admin/sys-administration/experiences/ExpCategories";

export default {
  name: "AdminExperiences",
  components: {
    ExpExperiences,
    ExpCategories,
  },
  data() {
    return {
      openTab: 1,
      categories: [],
    };
  },
  mounted() {
    this.$watch(
      (vm) => vm.categories,
      () => {
        this.categories.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
      },
      { immediate: true }
    );
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>

<style scoped></style>
