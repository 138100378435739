<template>
  <div>
    <modal
      v-if="show_modal"
      :label="`${
        editing ? 'Edit Experience Category' : 'Add Experience Category'
      }`"
      :submit_btn_text="'Save'"
      @submit="saveCategory($event)"
      @close="closeModal"
      blocking
      medium
      locked
    >
      <template v-slot:content>
        <AddEditCategory v-model="item_to_edit" />
      </template>
    </modal>

    <div class="flex">
      <div class="flex-auto">
        <label class="mb-2" for="student-textbox-filter"> Search </label>
        <input
          id="category_filter"
          type="text"
          v-model="filterText"
          class="border-steel-gray rounded h-8 w-2/3 ml-2"
        />
      </div>
      <div class="flex-auto text-right">
        <Button class="" text="Add New" @click="show_modal = true" />
      </div>
    </div>
    <div v-if="isLoading" class="profile_box">
      <LoadingSpinner />
    </div>
    <div v-else class="profile_box">
      <div>
        <dynamic-table
          :records="this.filtered_cats"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
          <template #cell(display)="{ item }">
            <div
              class="ml-5 w-5 rounded-full text-center"
              :class="{
                'bg-alert-success': item.display,
              }"
            >
              <font-awesome-icon v-if="item.display" icon="check" />
              <font-awesome-icon v-else icon="ban" />
            </div>
          </template>
          <template #cell(edit)="{ item }">
            <Button text="Edit" size="small" @click="openToEdit(item)" />
          </template>
          <template #cell(hide)="{ item }">
            <Button
              v-if="item.display"
              text="Hide"
              size="small"
              @click="hideCategory(item)"
            />
            <Button
              v-else
              text="Show"
              size="small"
              @click="showCategory(item)"
            />
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Button from "@/components/reusable-components/base-level-components/Button";
import AddEditCategory from "@/views/admin/sys-administration/experiences/AddEditCategory";
import { del, get, post, put } from "@/composables/httpUtil";
import { emitAlert } from "@/composables/alerts";

export default {
  name: "ExpCategories",
  components: {
    LoadingSpinner,
    DynamicTable,
    Button,
    AddEditCategory,
    Modal,
  },
  emits: ["reloadCats"],
  props: {
    modelValue: { type: Array, required: true },
  },
  data() {
    return {
      isLoading: true,
      filterText: "",
      filtered_cats: [],
      show_modal: false,
      editing: false,
      item_to_edit: {
        experience_category_id: "",
        description: "",
      },
      tableConfig: [
        {
          display: "Category Name",
          name: "description",
          sortable: false,
          class: "",
        },
        {
          display: "Displayed",
          name: "display",
          sortable: false,
          class: "",
        },
        {
          display: "Edit",
          name: "edit",
          sortable: false,
          class: "",
        },
        {
          display: "Hide / Show",
          name: "hide",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      let url = `admin/categories`;
      get(url).then((data) => {
        this.categories = data;
        this.filtered_cats = data;
        this.isLoading = false;
      });
    },
    openToEdit(item) {
      this.item_to_edit = item;
      this.editing = true;
      this.show_modal = true;
    },
    saveCategory() {
      if (this.item_to_edit.description) {
        if (this.item_to_edit.experience_category_id) {
          put(
            "admin/categories/" + this.item_to_edit.experience_category_id,
            this.item_to_edit
          ).then((data) => {
            emitAlert("success", `${data.description} Updated`, {
              timeout: 5000,
            });
          });
        } else {
          delete this.item_to_edit.experience_category_id;
          post("admin/categories/", this.item_to_edit).then((data) => {
            this.categories.push(data);
            this.fitered_cats.push(data);
            emitAlert("success", `${data.description} Added`, {
              timeout: 5000,
            });
          });
        }
        this.editing = false;
        this.show_modal = false;
        this.item_to_edit = {
          experience_category_id: "",
          description: "",
        };
      } else {
        emitAlert("error", `Category Needed`, {
          timeout: 5000,
        });
      }
    },
    hideCategory(item) {
      item.display = false;
      del("admin/categories/" + item.experience_category_id).then((data) => {
        emitAlert("success", `${data.description} Hidden`, {
          timeout: 5000,
        });
      });
    },
    showCategory(item) {
      item.display = true;
      put("admin/categories/" + item.experience_category_id, item).then(
        (data) => {
          emitAlert("success", `${data.description} Shown`, {
            timeout: 5000,
          });
        }
      );
    },
    closeModal() {
      this.show_modal = false;
      this.editing = false;
      this.item_to_edit = {
        experience_category_id: "",
        description: "",
      };
    },
  },
  computed: {
    categories: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    filterText: {
      handler() {
        if (this.filterText) {
          this.filtered_cats = this.categories.filter((c) => {
            return c.description
              .toLowerCase()
              .includes(this.filterText.toLowerCase());
          });
        } else {
          this.filtered_cats = this.categories;
        }
      },
    },
  },
};
</script>

<style scoped></style>
